<template>
    <div class="clue_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>创建时间</span>
                    <el-radio-group v-model="searchVal.dateChoice" size="small">
                        <el-radio-button
                            label="今日"
                            @click.native.prevent="clickitem('今日')"
                        ></el-radio-button>
                        <el-radio-button
                            label="近7天"
                            @click.native.prevent="clickitem('近7天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="本月"
                            @click.native.prevent="clickitem('本月')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        style="width: 240px"
                        v-model="searchVal.searchDate"
                        size="small"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="expireTimeOption"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 180px"
                        placeholder="公司名称、电话、编号"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="(pagesize = 20), (currentPage = 1), getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
                <p>
                    <el-button
                        class="right"
                        type="primary"
                        style="margin-left: 29px"
                        size="small"
                        @click="(pagesize = 20), (currentPage = 1), getData()"
                        >查询</el-button
                    >
                    <el-button
                        class="right"
                        @click="
                            (pagesize = 20), (currentPage = 1), searchEmpty()
                        "
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 20px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
            <div class="search-two">
                <Department
                    ref="department"
                    :departmentWidth="225"
                    :adminWidth="205"
                    :depSearch="btnP.depSearch"
                    :searchUser="btnP.userSearch"
                    @searchData="searchData"
                ></Department>
                <!-- <p v-if="btnP.depSearch">
                    <span style="margin-right: 40px">部门</span>
                    <el-select
                        v-model="searchVal.departmentId"
                        size="small"
                        filterable
                        style="width: 225px"
                        clearable
                        placeholder="请选择"
                        @change="changeDepartmentId"
                    >
                        <el-option
                            v-for="item in departmentList"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>

                <p v-if="btnP.userSearch">
                    <span>成员</span>
                    <el-select
                        filterable
                        style="width: 205px"
                        v-model="searchVal.adminId"
                        size="small"
                        clearable
                        placeholder="请选择"
                        @change="(pagesize = 20), (currentPage = 1), getData()"
                    >
                        <el-option
                            v-for="item in userName"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p> -->
                <p>
                    <!-- <span>线索产品</span>
                    <el-select
                        v-model="searchVal.productClue"
                        size="small"
                        filterable
                        style="width: 205px"
                        clearable
                        placeholder="请选择"
                        @change="(pagesize = 20), (currentPage = 1), getData()"
                    >
                        <el-option
                            v-for="item in $searchForm.productCategory()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select> -->
                    <clue-product
                        ref="clueproduct"
                        @changeProductId="changeProductId"
                        :productWidth="205"
                    ></clue-product>
                </p>
                <p>
                    <span>投放站点</span>
                    <el-select
                        v-model="searchVal.launchId"
                        size="small"
                        filterable
                        style="width: 205px"
                        clearable
                        placeholder="请选择"
                        @change="(pagesize = 20), (currentPage = 1), getData()"
                    >
                        <el-option
                            v-for="(item, index) in launchList"
                            :key="item.id"
                            :label="item.account"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
                <p>
                    <span>线索状态</span>
                    <el-select
                        v-model="searchVal.status"
                        size="small"
                        filterable
                        style="width: 205px"
                        clearable
                        placeholder="请选择"
                        @change="(pagesize = 20), (currentPage = 1), getData()"
                    >
                        <el-option label="待分配" :value="12"></el-option>
                        <el-option label="已分配" :value="14"></el-option>
                        <el-option label="已撤回" :value="13"></el-option>
                        <el-option label="跟进中" :value="5"></el-option>
                        <el-option label="已完成" :value="8"></el-option>
                    </el-select>
                </p>
                <p>
                    <span>已分配部门</span>
                    <el-select
                        v-model="searchVal.followDepartmentId"
                        size="small"
                        filterable
                        style="width: 205px"
                        clearable
                        placeholder="请选择"
                        @change="(pagesize = 20), (currentPage = 1), getData()"
                    >
                        <el-option
                            v-for="item in departmentList"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
            </div>
        </div>
        <div class="table_title">
            <p>
                <!-- <span>我的线索列表</span> -->
                <el-button
                    size="mini"
                    type="primary"
                    v-if="btnP.marketClue"
                    round
                    :class="searchVal.tableType == 1 ? 'isColor' : ''"
                    @click="(pagesize = 20), (currentPage = 1), onTab(1)"
                    >公司市场线索</el-button
                >
                <el-button
                    size="mini"
                    type="primary"
                    round
                    v-if="btnP.adminClue"
                    @click="(pagesize = 20), (currentPage = 1), onTab(2)"
                    :class="searchVal.tableType == 2 ? 'isColor' : ''"
                    >我创建的</el-button
                >

                <el-button
                    size="mini"
                    type="primary"
                    v-if="btnP.fillClue"
                    round
                    :class="searchVal.tableType == 3 ? 'isColor' : ''"
                    @click="(pagesize = 20), (currentPage = 1), onTab(3)"
                    >用户填报</el-button
                >
            </p>
            <p>
                <el-button
                    v-if="btnP.disDep && !isDistribution"
                    @click="distribution()"
                    type="primary"
                    style="
                        background: #fff;
                        border-radius: 2px;
                        padding: 0 12px 0 12px;
                        height: 24px;
                        color: #333333;
                        border-color: #d9d9d9;
                        font-size: 12px;
                    "
                >
                    <i
                        class="iconfont icon-jifenfenpei"
                        style="font-size: 8px"
                    ></i>
                    分配部门</el-button
                >
                <span v-if="isDistribution" style="font-size: 14px; color: 333"
                    >分配部门</span
                >
                <el-select
                    v-if="isDistribution"
                    v-model="followDepartmentId"
                    size="small"
                    filterable
                    style="width: 200px; margin-left: 10px"
                    placeholder="请选择或请输入搜索部门"
                >
                    <el-option
                        v-for="item in departmentList"
                        :key="item.id"
                        :label="item.departmentName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-button
                    v-if="isDistribution"
                    type="primary"
                    @click="subDistribution()"
                    style="
                        margin-right: 0;
                        background: rgb(35, 112, 235);
                        color: #fff;
                    "
                    class="subBtn"
                    size="small"
                >
                    保 存</el-button
                >
                <el-button
                    v-if="isDistribution"
                    type="info"
                    @click="onCancel()"
                    style="
                        margin-right: 0;
                        background: #fff;
                        color: #333;
                        border-color: #999;
                    "
                    class="subBtn"
                    size="small"
                >
                    取 消</el-button
                >

                <el-button
                    v-if="btnP.addClue"
                    type="primary"
                    style="
                        background: #fff;
                        border-radius: 2px;
                        padding: 0 12px 0 12px;
                        height: 24px;
                        color: #333333;
                        border-color: #d9d9d9;
                        font-size: 12px;
                    "
                    @click="onAdd()"
                >
                    <i class="el-icon-plus"></i>
                    添加线索</el-button
                >
            </p>
        </div>
        <div class="table">
            <Table
                ref="table"
                @pageData="pageData"
                @telSearch="telSearch"
            ></Table>
        </div>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 分配部门 -->
        <el-dialog
            :visible.sync="dialogDistribution"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    分配部门
                </div>
            </template>
            <div class="dialog">
                <DistDep ref="distdep" @close="handleClose" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Table from '../table/table.vue';
import { departmentList } from '@/api/admin/framework/framework.js';
import { launchList } from '@/api/user/launch/manage.js';
import { configList } from '@/api/configure/configure';
import DistDep from '../distDep/distDep.vue';
import { clueAllotDep } from '@/api/clue/meClue';
import { roleList } from '@/api/customer/customer.js';
import Department from '../../../../components/Department.vue';
import clueProduct from '../../../../components/clueProduct.vue';
export default {
    components: {
        Table,
        DistDep,
        Department,
        clueProduct,
    },
    data() {
        return {
            searchVal: {
                name: '',
                dateChoice: '',
                searchDate: [],
                sourceCategory: 0,
                productClue: '',
                launchId: '',
                tableType: '',
                followDepartmentId: '',
                status: '',
            },
            departmentList: [],
            userName: [],
            launchList: [],
            configList: [],
            btnP: {},
            pagesize: 20,
            total: 0,
            currentPage: 1,
            dialogDistribution: false,
            isDistribution: false,
            isPage: false,
            followDepartmentId: '',
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() >= Date.now();
                },
            },
        };
    },

    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            this.getLaunchList();

            this.getDepartmentList();
            if (btn.marketClue) {
                this.searchVal.tableType = 1;
            }
            if (!btn.marketClue && btn.adminClue) {
                this.searchVal.tableType = 2;
            }
            if (!btn.marketClue && !btn.adminClue && btn.fillClue) {
                this.searchVal.tableType = 3;
            }
            if (!btn.depSearch && btn.userSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }

            if (sessionStorage.getItem('pageData')) {
                let pageData = JSON.parse(sessionStorage.getItem('pageData'));

                this.searchVal = pageData.param;
                this.$refs.clueproduct.$data.productId =
                    this.searchVal.productClue;
                this.$refs.department.$data.departmentId =
                    pageData.param.departmentId || undefined;
                this.$refs.department.$data.adminId =
                    pageData.param.adminId || '';
                console.log(this.$refs.department.$data.adminId);
                if (pageData.param.adminId) {
                    this.$refs.department.getUserList(
                        pageData.param.departmentId
                    );
                }
                if (pageData.param.entryType != 0) {
                    this.searchVal.entryType = 1;
                }
                this.currentPage = pageData.pageNum;
                this.pagesize = pageData.pageSize;
                sessionStorage.removeItem('pageData');

                this.isPage = true;
                console.log(this.searchVal);
            } else {
                this.isPage = true;
            }
            this.$refs.clueproduct.getData();
            this.getData();
        },

        // 投放列表
        getLaunchList() {
            let data = {
                param: { status: 1 },
                pageNum: 0,
                pageSize: 0,
            };
            launchList(data).then((res) => {
                if (res.code == 200) {
                    this.launchList = res.data.list;
                }
            });
        },
        onTab(i) {
            this.searchVal.tableType = i;
            this.getData();
        },
        telSearch(row) {
            this.searchVal.name = row.contactContent;
            this.getData();
        },
        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        changeProductId(val) {
            this.pagesize = 20;
            this.currentPage = 1;
            this.searchVal.productClue = val;
            this.getData();
        },
        // 搜索获取列表
        getData() {
            let data = {
                param: {
                    name: this.searchVal.name,
                    dateChoice: this.searchVal.dateChoice,
                    searchDate: this.searchVal.searchDate,
                    sourceCategory: this.searchVal.sourceCategory,
                    sourceCategory: 1,
                    tableType: this.searchVal.tableType,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };

            if (this.searchVal.dateChoice != '') {
                let date = new Date();
                data.param.endCreateDate = this.$searchTime.getNextDate(
                    date,
                    0
                );
                this.searchVal.searchDate = [];
                if (this.searchVal.dateChoice == '今日') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        0
                    );
                } else if (this.searchVal.dateChoice == '近7天') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        7
                    );
                } else if (this.searchVal.dateChoice == '本月') {
                    data.param.startCreateDate =
                        this.$searchTime.thisMonth()[0];
                }
            }
            if (
                this.searchVal.searchDate &&
                this.searchVal.searchDate.length > 0
            ) {
                data.param.startCreateDate = this.searchVal.searchDate[0];
                data.param.endCreateDate = this.searchVal.searchDate[1];
            }
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (this.searchVal.departmentId) {
                    data.param.departmentId = this.searchVal.departmentId;
                }
            }
            if (this.btnP.userSearch) {
                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }

            if (this.searchVal.productClue != '') {
                data.param.productClue = this.searchVal.productClue;
            }
            if (this.searchVal.status != '') {
                data.param.status = this.searchVal.status;
            }
            if (this.searchVal.followDepartmentId != '') {
                data.param.followDepartmentId =
                    this.searchVal.followDepartmentId;
            }
            if (this.searchVal.tableType == 2) {
                data.param.adminId = sessionStorage.getItem('adminId');
                data.param.entryType = 1;
            }
            if (this.searchVal.launchId != '') {
                data.param.launchId = this.searchVal.launchId;
            }
            if (this.searchVal.tableType == 3) {
                data.param.entryType = 0;
            }
            console.log(this.searchVal.tableType);
            setTimeout(() => {
                this.$refs.table.getData(this.btnP, data);
            }, 0);
        },
        pageData(i) {
            this.total = i;
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 单选时间
        clickitem(e) {
            e === this.searchVal.dateChoice
                ? (this.searchVal.dateChoice = '')
                : (this.searchVal.dateChoice = e);
            this.searchVal.searchDate = [];
            this.getData();
        },

        // 时间输入框变化
        expireTime() {
            if (
                this.searchVal.searchDate &&
                this.searchVal.searchDate.length > 0
            ) {
                this.searchVal.dateChoice = '';
            }
            this.getData();
        },
        onAdd() {
            this.$router.push('/marketaddclue');
        },
        // 分配部门(头部)
        distribution() {
            this.isDistribution = true;
            setTimeout(() => {
                this.$refs.table.getSelection(true);
            });
            this.getDepartmentList();
        },
        onCancel() {
            this.isDistribution = false;
            this.getData();
            setTimeout(() => {
                this.$refs.table.getSelection(false);
            });
        },

        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                if (res.code == 200) {
                    this.departmentList = res.data;
                    this.followDepartmentId = '';
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        // 分配部门
        onDistDep() {
            let arr = this.$refs.table.multipleSelection;

            arr.forEach((item, i) => {
                if (item.sourceCategory == 2 || item.status == 14) {
                    arr.splice(i, 1);
                }
            });
            console.log(arr);
            if (arr.length == 0) {
                return this.$message.error('请选择线索');
            } else {
                this.dialogDistribution = true;
                setTimeout(() => {
                    this.$refs.distdep.getData(arr);
                });
            }
        },
        subDistribution() {
            let idArr = [];
            let arr = this.$refs.table.multipleSelection;
            if (arr.length == 0) {
                return this.$message.error('请选择线索');
            }
            for (let i = 0; i < arr.length; i++) {
                idArr.push({ id: arr[i].id });
            }
            if (!this.followDepartmentId) {
                return this.$message.error('请选择部门');
            }
            let data = {
                param: {
                    followDepartmentId: this.followDepartmentId,
                },
            };

            data.param.lineDOList = idArr;
            clueAllotDep(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onCancel();
                    this.followDepartmentId = '';
                }
            });
        },
        // 关闭抽屉
        handleClose() {
            this.dialogDistribution = false;
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {
                name: '',
                dateChoice: '',
                searchDate: [],
                sourceCategory: 0,
                followAdminIdVal: '',
                productClueVal: '',
                launchId: '',
                entryType: 1,
            };
            this.getBtn();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        p {
            display: inline-block;
            .el_radio_button_div {
                font-size: 14px;
                width: 70px;
            }
        }
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }

    .table_title {
        background: #fff;
        padding: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            button {
                background: #fff;
                border-color: #f0f0f0;
                color: #666666;
            }
            .isColor {
                border-color: #fff;
                color: #2370eb;
                background: #e9f2ff;
            }
        }
    }
    .table {
        flex: 1;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .clue_btn {
        margin-top: 10px;
        position: absolute;
        top: -50px;
        right: 0;
        span {
            display: inline-block;
            padding: 10px 20px;
            margin-left: 15px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            background-color: #fff;
        }
    }
}
.clue_assist_body {
    padding: 42px 74px 100px;
    .user {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        .left-spot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
    .user:last-child {
        .left-spot {
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #fff;
            border: 1px solid #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}

/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
/deep/ .el-textarea__inner {
    resize: none;
}
.search-two {
    p {
        margin-bottom: 10px;
    }
}
</style>
