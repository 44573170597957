<template>
    <div class="distDep">
        <div class="content">
            <p class="title">
                <span>线索产品</span>
                {{ $tableDataHandle.productCategory(data.productClue) }}
            </p>
            <p class="title">
                <span>客户名称</span>
                {{ data.companyName || '- -' }}
            </p>
            <p class="title">
                <span>分配部门</span>
                <el-select
                    v-model="form.departmentId"
                    size="small"
                    filterable
                    style="width:270px"
                    placeholder="请选择或请输入搜索部门"
                >
                    <el-option
                        v-for="item in dempData"
                        :key="item.id"
                        :label="item.departmentName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </p>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left:250px"
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;"
                size="mini"
                @click="onSubmit"
                >确认</el-button
            >
        </div>
    </div>
</template>
<script>
import { departmentList } from '@/api/admin/framework/framework.js';
import { clueAllotDep } from '@/api/clue/meClue';
export default {
    data() {
        return {
            data: {},
            form: {
                departmentId: '',
            },
            dempData: [],
        };
    },
    methods: {
        getData(data) {
            this.data = data;
            let obj = {
                param: {},
            };
            departmentList(obj).then((res) => {
                this.dempData = res.data;
            });
        },
        onSubmit() {
            if (!this.form.departmentId) {
                return this.$message.error('请选择部门');
            }
            let data = {
                param: {
                    followDepartmentId: this.form.departmentId,
                },
            };

            data.param.lineDOList = [{ id: this.data.id }];
            clueAllotDep(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.form.departmentId = '';
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.distDep {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    width: 100%;
    .content {
        flex: 1;
        .title {
            display: flex;
            padding: 16px 0;
            font-size: 14px;
            color: #333333;
            span {
                font-weight: 600;
                display: inline-block;
                width: 100px;
                text-align: left;
            }
        }
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        z-index: 1000;
        bottom: 0;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
